export const authInputProperties = {
  size: "lg",
  styles: {
    root: {
      borderRadius: 2,
    },
    input: {
      fontSize: 16,
    },
  },
} as const;
