import { z } from "zod";

import { zRequiredString } from "@/utils/zodValidations";

export const signInFormSchema = z.object({
  email: zRequiredString(),
  password: zRequiredString(),
});

export type SignInFormValues = z.infer<typeof signInFormSchema>;
