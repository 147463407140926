import { Anchor, Box, Stack } from "@mantine/core";
import type { GetServerSideProps } from "next";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { getPlainLayout } from "@/components/layouts";
import SignInForm from "@/features/sign-in/components/SignInForm";
import { parseServerAuth } from "@/gateways/authStore";
import { NextPageWithLayout } from "@/types";
import { showSuccessNotification } from "@/utils/notifications";

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  if (ctx.query.emailChangeConfirmed) {
    return {
      props: {},
    };
  }
  const auth = parseServerAuth(ctx);
  if (auth) {
    return {
      redirect: {
        permanent: false,
        destination: "/employees",
      },
    };
  }
  return {
    props: {},
  };
};

const SignIn: NextPageWithLayout = () => {
  const { query } = useRouter();
  useEffect(() => {
    if (query.emailChangeConfirmed) {
      showSuccessNotification("メールアドレスが変更されました");
    }
    if (query.requiredLogin) {
      showSuccessNotification("ログインしてください");
    }
  }, [query]);

  return (
    <Stack pt={150} align="center" spacing={0} sx={{ minWidth: 300 }}>
      <Box sx={{ width: 270 }} mb={32}>
        <Image src="/images/logo.png" alt="らくしふ労務管理" width={270} height={40} />
      </Box>
      <SignInForm />
      <Link href="/password/new" legacyBehavior>
        <Anchor mt={40}>ログインパスワードの再設定はこちら</Anchor>
      </Link>
    </Stack>
  );
};

SignIn.getLayout = getPlainLayout;

export default SignIn;
