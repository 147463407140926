import { z } from "zod";

export const zPassword = (requiredMessage = "半角英数字10文字以上で入力してください") =>
  z
    .string({ required_error: requiredMessage })
    .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, { message: "半角英数字10文字以上で入力してください" })
    .min(10, { message: "半角英数字10文字以上で入力してください" });

export const zKatakana = (props?: { required: boolean }) => {
  const { required = false } = props || {};
  const options = { message: "全角カナで入力してください" };
  if (required) {
    return z.string().regex(/^[ァ-ヴー\s]+$/, options);
  } else {
    return z
      .string()
      .regex(/^[ァ-ヴー\s]*$/, options)
      .optional();
  }
};

export const zIntegerString = (props?: {
  required?: boolean;
  message?: string;
  length?: number;
  minLength?: number;
  maxLength?: number;
}) => {
  const { required, length, minLength, maxLength } = props || {};
  const message =
    props?.message ||
    (length
      ? `半角数字${length}桁で入力してください`
      : `半角数字${minLength ? `${minLength}桁以上` : ""}${
          maxLength ? `${maxLength}桁以内` : ""
        }で入力してください`);
  if (required) {
    const regex =
      length === undefined && minLength === undefined && maxLength === undefined
        ? /^\d{1,}$/
        : length
        ? new RegExp(String.raw`^\d{${length}}$`)
        : new RegExp(String.raw`^\d{${minLength || 1},${maxLength || ""}}$`);
    return z.string().regex(regex, { message });
  }
  const regex =
    length === undefined && minLength === undefined && maxLength === undefined
      ? /^(|\d*?)$/
      : length !== undefined
      ? new RegExp(String.raw`^(|\d{${length}})$`)
      : new RegExp(String.raw`^(|\d{${minLength || 0},${maxLength || ""}})$`);
  return z.string().regex(regex, { message }).optional();
};

export const zRequiredString = (message = "入力してください") => {
  return z.string({ required_error: message }).trim().min(1, { message });
};

export const zMobilePhoneNumber = (options?: { optional: true }) => {
  const { optional = true } = options || {};
  if (optional) {
    return z
      .string()
      .regex(/^(0\d{9,10}|)$/, "正しい電話番号を入力してください")
      .optional();
  }
  return z
    .string({ required_error: "電話番号を入力してください" })
    .regex(/^0\d{9,10}$/, "正しい電話番号を入力してください");
};

export const zFile = typeof window === "undefined" ? z.any() : z.instanceof(File);
export const zRequiredFile = (message = "ファイルを選択してください") => {
  return typeof window === "undefined" ? z.any() : z.instanceof(File, { message });
};

export const zMemo = (max: number = 500) =>
  z
    .string()
    .max(max, { message: `${max}文字以内で入力してください` })
    .optional();
