import { authInputProperties } from "@/styles/auth";
import { COLORS } from "@/styles/colors";

import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Group, PasswordInput, Space, Text, TextInput } from "@mantine/core";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useAuth } from "@/contexts/AuthContext";
import { SignInFormValues, signInFormSchema } from "@/features/sign-in/constants";

const SignInForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormValues>({ resolver: zodResolver(signInFormSchema) });
  const { login } = useAuth();
  const router = useRouter();
  const [loginFailMessage, setLoginFailMessage] = useState<string | undefined>();

  const onSubmit: SubmitHandler<SignInFormValues> = async ({ email, password }) => {
    const errorMessage = await login(email, password);
    if (errorMessage) {
      setLoginFailMessage(errorMessage);
    } else {
      router.push("/employees");
    }
  };

  const hasError = useMemo(
    () => !!loginFailMessage || !!errors.email || !!errors.password,
    [errors, loginFailMessage]
  );

  return (
    <Box
      pt={24}
      px={24}
      pb={16}
      sx={{
        width: "calc(100% - 32px)",
        maxWidth: 400,
        backgroundColor: COLORS.gray100,
        borderRadius: 8,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInput
              aria-label="メールアドレス"
              placeholder="メールアドレス"
              icon={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="lg"
                  color={field.value?.length ? COLORS.black : COLORS.gray400}
                />
              }
              error={hasError}
              {...authInputProperties}
              {...field}
            />
          )}
        />
        <Space h={16} />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              aria-label="パスワード"
              placeholder="パスワード"
              icon={
                <FontAwesomeIcon
                  icon={faLock}
                  size="lg"
                  color={field.value?.length ? COLORS.black : COLORS.gray400}
                />
              }
              error={hasError}
              {...authInputProperties}
              {...field}
            />
          )}
        />

        {!!hasError && (
          <Text color="red" mt={16} align="center">
            メールアドレスまたはパスワードが正しくありません
          </Text>
        )}
        <Button
          type="submit"
          loading={isSubmitting}
          fullWidth
          mt={24}
          styles={{
            root: { height: 52 },
            label: { fontSize: 16 },
          }}
        >
          ログイン
        </Button>
      </form>
    </Box>
  );
};

export default SignInForm;
